<template>
    <section class="emsculpt-feedback py-5 overflow-hidden">
        <div class="container">
            <h1 class="d-none d-lg-block text-center display-4 font-weight-bold mb-5"
                data-aos="flip-up"
                data-aos-duration="1000">
                用家分享
            </h1>

            <h1 class="d-lg-none text-center font-weight-bold mb-4"
                data-aos="flip-up"
                data-aos-duration="1000">
                用家分享
            </h1>

            <div class="position-relative d-lg-none">
                <div class="position-absolute btn-swiper-wrapper left d-flex align-items-center"
                     data-aos="fade-up"
                     data-aos-duration="1000">
                    <button class="btn-swiper text-white display-4 h1"
                            v-on:click="slidePrev">
                        <font-awesome-icon icon="caret-left"></font-awesome-icon>
                    </button>
                </div>

                <swiper ref="swiper"
                        v-bind:options="swiperOptions">
                    <swiper-slide class="h-auto">
                        <div class="d-flex flex-column h-100">
                            <h3 class="font-weight-bold text-center mb-3">
                                產後媽咪
                            </h3>

                            <div class="feedbacks flex-grow-1 d-flex">
                                <div class="position-relative feedback-group d-flex flex-column justify-content-evenly p-3">
                                    <intima-feedback-card class="mb-3">
                                        <template v-slot:avatar>
                                            <img class="rounded-lg h-100 w-100"
                                                 v-bind:alt="``"
                                                 src="../../../assets/campaign/intima/feedback/coyi.jpg">
                                        </template>
                                        <template v-slot:default>
                                            <h3 class="text-heading font-weight-bold mb-0">
                                                Coyi
                                            </h3>
                                            <h6 class="text-dark font-weight-normal">
                                                專業化妝師、媽媽
                                            </h6>
                                            <p class="text-dark text-justify">
                                                收陰機有效收緊內壁、修復陰唇，幫我解決產後鬆弛、暗沉問題。令我最驚喜是療程後膠原仍持續增生，2星期後收窄效果更明顯！
                                            </p>
                                        </template>
                                    </intima-feedback-card>
                                    <intima-feedback-card class="mb-3">
                                        <template v-slot:avatar>
                                            <img class="rounded-lg h-100 w-100"
                                                 v-bind:alt="``"
                                                 src="../../../assets/campaign/intima/feedback/annie.jpg">
                                        </template>
                                        <template v-slot:default>
                                            <h3 class="text-heading font-weight-bold mb-0">
                                                Annie
                                            </h3>
                                            <h6 class="text-dark font-weight-normal">
                                                專業化妝師、媽媽
                                            </h6>
                                            <p class="text-dark text-justify">
                                                收陰機改善私密位置的鬆弛，並提升尿道的支持力。療程只需8分鐘，即時感到陰道緊緊包圍探頭，全程微溫無痛，而且無復原期，舒適方便！
                                            </p>
                                        </template>
                                    </intima-feedback-card>
                                    <intima-feedback-card class="">
                                        <template v-slot:avatar>
                                            <img class="rounded-lg h-100 w-100"
                                                 v-bind:alt="``"
                                                 src="../../../assets/campaign/intima/feedback/miracle.jpg">
                                        </template>
                                        <template v-slot:default>
                                            <h3 class="text-heading font-weight-bold mb-0">
                                                Miracle
                                            </h3>
                                            <h6 class="text-dark font-weight-normal">
                                                瑜伽導師、媽媽
                                            </h6>
                                            <p class="text-dark text-justify">
                                                產後雖然有做收陰運動，但盆底肌始終未回復產前狀態。幸好收陰機幫我改善尿頻、敏感度下降及色素問題，長遠減低子宮下垂風險！
                                            </p>
                                        </template>
                                    </intima-feedback-card>
                                </div>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide class="h-auto">
                        <div class="d-flex flex-column h-100">
                            <h3 class="font-weight-bold text-center mb-3">
                                年輕女性
                            </h3>
                            <div class="feedbacks flex-grow-1 d-flex">
                                <div class="position-relative feedback-group d-flex flex-column justify-content-evenly p-3">
                                    <intima-feedback-card class="mb-3">
                                        <template v-slot:avatar>
                                            <img class="rounded-lg h-100 w-100"
                                                 v-bind:alt="``"
                                                 src="../../../assets/campaign/intima/feedback/nie.jpg">
                                        </template>
                                        <template v-slot:default>
                                            <h3 class="text-heading font-weight-bold mb-0">
                                                Nie
                                            </h3>
                                            <h6 class="text-dark font-weight-normal">
                                                模特兒
                                            </h6>
                                            <p class="text-dark text-justify">
                                                超聲波結合單極射頻技術，為陰道增生膠原及彈力蛋白，第一次做收陰機已即時Feel到緊緻效果！而且所有探頭均用完即棄，令我衛生又放心！
                                            </p>
                                        </template>
                                    </intima-feedback-card>
                                    <intima-feedback-card class="mb-3">
                                        <template v-slot:avatar>
                                            <img class="rounded-lg h-100 w-100"
                                                 v-bind:alt="``"
                                                 src="../../../assets/campaign/intima/feedback/ursula.jpg">
                                        </template>
                                        <template v-slot:default>
                                            <h3 class="text-heading font-weight-bold mb-0">
                                                Ursula
                                            </h3>
                                            <h6 class="text-dark font-weight-normal">
                                                美妝博客
                                            </h6>
                                            <p class="text-dark text-justify">
                                                收陰機不但能改善密唇形態、美白顏色，還可重新緊緻陰道內壁，恢復飽滿彈性，敏感度大大提升！值得推薦給年輕女性！
                                            </p>
                                        </template>
                                    </intima-feedback-card>
                                    <intima-feedback-card class="">
                                        <template v-slot:avatar>
                                            <img class="rounded-lg h-100 w-100"
                                                 v-bind:alt="``"
                                                 src="../../../assets/campaign/intima/feedback/venus.jpg">
                                        </template>
                                        <template v-slot:default>
                                            <h3 class="text-heading font-weight-bold mb-0">
                                                Venus
                                            </h3>
                                            <h6 class="text-dark font-weight-normal">
                                                時尚主播
                                            </h6>
                                            <p class="text-dark text-justify">
                                                收陰機經美國FDA及歐盟CE安全認證，除了收緊陰道，還能改善女性常見的痕癢異味、乾澀不適等問題，不會造成傷口，完成後可如常工作！
                                            </p>
                                        </template>
                                    </intima-feedback-card>
                                </div>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>

                <div class="position-absolute btn-swiper-wrapper right d-flex align-items-center"
                     data-aos="fade-up"
                     data-aos-duration="1000">
                    <button class="btn-swiper text-white display-4 h1"
                            v-on:click="slideNext">
                        <font-awesome-icon icon="caret-right"></font-awesome-icon>
                    </button>
                </div>
            </div>

            <div class="d-none d-lg-flex row">
                <div class="col-lg-6 d-flex flex-column mb-3">
                    <h3 class="font-weight-bold text-center mb-3">
                        產後媽咪
                    </h3>

                    <div class="feedbacks flex-grow-1 d-flex">
                        <div class="position-relative feedback-group d-flex flex-column justify-content-evenly p-3">
                            <intima-feedback-card class="mb-3">
                                <template v-slot:avatar>
                                    <img class="rounded-lg h-100 w-100"
                                         v-bind:alt="``"
                                         src="../../../assets/campaign/intima/feedback/coyi.jpg">
                                </template>
                                <template v-slot:default>
                                    <h3 class="text-heading font-weight-bold mb-0">
                                        Coyi
                                    </h3>
                                    <h6 class="text-dark font-weight-normal">
                                        專業化妝師、媽媽
                                    </h6>
                                    <p class="text-dark text-justify">
                                        收陰機有效收緊內壁、修復陰唇，幫我解決產後鬆弛、暗沉問題。令我最驚喜是療程後膠原仍持續增生，2星期後收窄效果更明顯！
                                    </p>
                                </template>
                            </intima-feedback-card>
                            <intima-feedback-card class="mb-3">
                                <template v-slot:avatar>
                                    <img class="rounded-lg h-100 w-100"
                                         v-bind:alt="``"
                                         src="../../../assets/campaign/intima/feedback/annie.jpg">
                                </template>
                                <template v-slot:default>
                                    <h3 class="text-heading font-weight-bold mb-0">
                                        Annie
                                    </h3>
                                    <h6 class="text-dark font-weight-normal">
                                        專業化妝師、媽媽
                                    </h6>
                                    <p class="text-dark text-justify">
                                        改善私密位置的鬆弛，並提升尿道的支持力。療程只需8分鐘，即時感到陰道緊緊包圍探頭，全程微溫無痛，而且無復原期，舒適方便！
                                    </p>
                                </template>
                            </intima-feedback-card>
                            <intima-feedback-card class="">
                                <template v-slot:avatar>
                                    <img class="rounded-lg h-100 w-100"
                                         v-bind:alt="``"
                                         src="../../../assets/campaign/intima/feedback/miracle.jpg">
                                </template>
                                <template v-slot:default>
                                    <h3 class="text-heading font-weight-bold mb-0">
                                        Miracle
                                    </h3>
                                    <h6 class="text-dark font-weight-normal">
                                        瑜伽導師、媽媽
                                    </h6>
                                    <p class="text-dark text-justify">
                                        產後雖然有做收陰運動，但盆底肌始終未回復產前狀態。幸好收陰機幫我改善尿頻、敏感度下降及色素問題，長遠減低子宮下垂風險！
                                    </p>
                                </template>
                            </intima-feedback-card>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 d-flex flex-column mb-3">
                    <h3 class="font-weight-bold text-center mb-3">
                        年輕女性
                    </h3>

                    <div class="feedbacks flex-grow-1 d-flex">
                        <div class="position-relative feedback-group d-flex flex-column justify-content-evenly p-3">
                            <intima-feedback-card class="mb-3">
                                <template v-slot:avatar>
                                    <img class="rounded-lg h-100 w-100"
                                         v-bind:alt="``"
                                         src="../../../assets/campaign/intima/feedback/nie.jpg">
                                </template>
                                <template v-slot:default>
                                    <h3 class="text-heading font-weight-bold mb-0">
                                        Nie
                                    </h3>
                                    <h6 class="text-dark font-weight-normal">
                                        模特兒
                                    </h6>
                                    <p class="text-dark text-justify">
                                        超聲波結合單極射頻技術，為陰道增生膠原及彈力蛋白，第一次做已即時Feel到緊緻效果！而且所有探頭均用完即棄，令我衛生又放心！
                                    </p>
                                </template>
                            </intima-feedback-card>
                            <intima-feedback-card class="mb-3">
                                <template v-slot:avatar>
                                    <img class="rounded-lg h-100 w-100"
                                         v-bind:alt="``"
                                         src="../../../assets/campaign/intima/feedback/ursula.jpg">
                                </template>
                                <template v-slot:default>
                                    <h3 class="text-heading font-weight-bold mb-0">
                                        Ursula
                                    </h3>
                                    <h6 class="text-dark font-weight-normal">
                                        美妝博客
                                    </h6>
                                    <p class="text-dark text-justify">
                                        收陰機不但能改善密唇形態、美白顏色，還可重新緊緻陰道內壁，恢復飽滿彈性，敏感度大大提升！值得推薦給年輕女性！
                                    </p>
                                </template>
                            </intima-feedback-card>
                            <intima-feedback-card class="">
                                <template v-slot:avatar>
                                    <img class="rounded-lg h-100 w-100"
                                         v-bind:alt="``"
                                         src="../../../assets/campaign/intima/feedback/venus.jpg">
                                </template>
                                <template v-slot:default>
                                    <h3 class="text-heading font-weight-bold mb-0">
                                        Venus
                                    </h3>
                                    <h6 class="text-dark font-weight-normal">
                                        時尚主播
                                    </h6>
                                    <p class="text-dark text-justify">
                                        收陰機經美國FDA及歐盟CE安全認證，除了收緊陰道，還能改善女性常見的痕癢異味、乾澀不適等問題，不會造成傷口，完成後可如常工作！
                                    </p>
                                </template>
                            </intima-feedback-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import "swiper/css/swiper.min.css";
import {
    Swiper,
    SwiperSlide,
} from "vue-awesome-swiper";
import IntimaFeedbackCard from "./FeedbackCard";

export default {
    name: "IntimaFeedback",
    components: {
        IntimaFeedbackCard,
        Swiper,
        SwiperSlide,
    },
    data () {
        return {
            "swiperOptions": {
                "loop": true,
                "effect": "cube",
                "slidesPerView": 1,
                "spaceBetween": 0,
            },
        };
    },
    computed: {
        swiperInstance () {
            if (this.$refs.swiper) {
                return this.$refs.swiper.$swiper;
            }
            return {};
        },
    },
    methods: {
        slidePrev () {
            this.swiperInstance.slidePrev();
        },

        slideNext () {
            this.swiperInstance.slideNext();
        },
    },
}
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "./variables.scss";

.emsculpt-feedback {
    background-image: url($background);
    background-color: $background-color;
    color: $text-color;

    .feedbacks {
        & > .feedback-group {
            background-color: $background-color-alt;
            border-radius: 25px;
        }
    }

    .btn-swiper-wrapper {
        width: 33px;
        height: 100%;

        top: 0;

        &.left {
            left: -17.5px;
        }

        &.right {
            right: -17.5px;
        }

        @include media-breakpoint-up(sm) {
            &.left {
                left: -35px;
            }

            &.right {
                right: -35px;
            }
        }

        & > .btn-swiper {
            background: none;
            outline: none;
            border: none;

            filter: drop-shadow(0px 0px 5px rgba(0, 0, 0 ,0.25));
            z-index: 9;
        }
    }

    .text-heading {
        color: $heading-color;
    }

    .justify-content-evenly {
        justify-content: space-evenly !important;
    }

    .rounded-lg {
        border-radius: 15px !important;
    }
}
</style>
